import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  contactUs,
  changeCurrency,
  getNotifications,
  joinTeamRequest,
  getPayeeDetails,
  getGoogleReviews,
  sendGoogleId,
  disconnetGoogle
} from "./endpoint";
import { baseUrl } from "../../utilis/baseUrl";
import { allNotifications, allReviews } from "./slices";
import { store } from "../store";
import { toast } from "react-toastify";
import { login } from "../Auth/slices";
export const UserApi = createApi({
  reducerPath: "User",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  tagTypes: ["User"],
  endpoints: (builder) => ({
    joinTeamRequest: builder.mutation({
      query: (requestBody) => {
        const token = store.getState().Auth.user;
        requestBody["userId"] = token?.user?.id;
        return {
          url: joinTeamRequest,
          method: "POST",
          body: requestBody,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + token?.accessToken,
          },
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        // dispatch(messageCreated('Fetching posts...'))
        try {
          const { data } = await queryFulfilled;

          toast.success("team request sent successfully", {
            autoClose: 5000,
          });
          // `onSuccess` side-effect
          // dispatch(messageCreated('Posts received!'))
        } catch (err) {
          // `onError` side-effect
          // dispatch(messageCreated('Error fetching posts!'))
          if (err?.error?.error) {
            toast?.error("Network error");
          }
          toast.error(err?.error?.data?.data?.message, {
            autoClose: 5000,
          });
        }
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
      },
      invalidatesTags: ["User"],
    }),
    ContactUs: builder.mutation({
      query: (contactBody) => {
        const token = store.getState().Auth.user?.accessToken;
        return {
          url: contactUs,
          method: "POST",
          body: contactBody,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + token,
          },
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        // dispatch(messageCreated('Fetching posts...'))
        try {
          const { data } = await queryFulfilled;

          toast.success("query submitted successfully", {
            autoClose: 5000,
          });
          // `onSuccess` side-effect
          // dispatch(messageCreated('Posts received!'))
        } catch (err) {
          // `onError` side-effect
          // dispatch(messageCreated('Error fetching posts!'))
          if (err?.error?.error) {
            toast?.error("Network error");
          }
          toast.error(err?.error?.data?.data?.message, {
            autoClose: 5000,
          });
        }
      },
      invalidatesTags: ["User"],
    }),
    getAllNotifications: builder.query({
      query: (params) => {
        const token = store.getState().Auth.user?.accessToken;
        return {
          url: getNotifications,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data } = await queryFulfilled;
          dispatch(allNotifications(data?.data?.threads));

          // dispatch(transactions());
          // `onSuccess` side-effect
          // dispatch(messageCreated('Posts received!'))
        } catch (err) {
          // `onError` side-effect
          // dispatch(messageCreated('Error fetching posts!'))
        }
      },
      providesTags: ["User"],
    }),
    getGoogleReviews: builder.query({
      query: (params) => {
        const token = store.getState().Auth.user?.accessToken;
        return {
          url: getGoogleReviews,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data } = await queryFulfilled;
          // dispatch(allReviews(data?.data));

          // dispatch(transactions());
          // `onSuccess` side-effect
          // dispatch(messageCreated('Posts received!'))
        } catch (err) {
          // `onError` side-effect
          // dispatch(messageCreated('Error fetching posts!'))
        }
      },
      providesTags: ["User"],
    }),
    disconnetGoogle: builder.query({
      query: (params) => {
        const token = store.getState().Auth.user?.accessToken;
        return {
          url: disconnetGoogle,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data } = await queryFulfilled;
          // dispatch(allReviews(data?.data));

          // dispatch(transactions());
          // `onSuccess` side-effect
          // dispatch(messageCreated('Posts received!'))
        } catch (err) {
          // `onError` side-effect
          // dispatch(messageCreated('Error fetching posts!'))
        }
      },
      providesTags: ["User"],
    }),
    sendGoogleId: builder.mutation({
      query: (Body) => {
        const user = store.getState().Auth.user;
        return {
          url: sendGoogleId,
          method: "POST",
          body: Body,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + user?.accessToken,
          },
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        // dispatch(messageCreated('Fetching posts...'))
        try {
          const { data } = await queryFulfilled;

          // `onSuccess` side-effect
          // dispatch(messageCreated('Posts received!'))
        } catch (err) {
          // `onError` side-effect
          // dispatch(messageCreated('Error fetching posts!'))
          if (err?.error?.error) {
            toast?.error("Network error");
          }
          toast.error(err?.error?.data?.data?.message, {
            autoClose: 5000,
          });
        }
      },
      invalidatesTags: ["User"],
    }),
    ChangeCurrency: builder.mutation({
      query: (Body) => {
        localStorage.setItem("currency", Body.currency);
        const user = store.getState().Auth.user;
        return {
          url: changeCurrency,
          method: "POST",
          body: Body,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + user?.accessToken,
          },
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        // dispatch(messageCreated('Fetching posts...'))
        try {
          const { data } = await queryFulfilled;
          const user = store.getState().Auth.user;

          let userData = Object.assign({}, user?.user);

          userData.currency = localStorage.getItem("currency");

          userData = { user: userData, accessToken: user?.accessToken };
          dispatch(login(userData));
          toast.success("Currency updated", {
            autoClose: 5000,
          });
          // `onSuccess` side-effect
          // dispatch(messageCreated('Posts received!'))
        } catch (err) {
          // `onError` side-effect
          // dispatch(messageCreated('Error fetching posts!'))
          if (err?.error?.error) {
            toast?.error("Network error");
          }
          toast.error(err?.error?.data?.data?.message, {
            autoClose: 5000,
          });
        }
      },
      invalidatesTags: ["User"],
    }),
    getPayeeDetails: builder.query({
      query: (params) => {
        const token = store.getState().Auth.user?.accessToken;
        return {
          url: `${getPayeeDetails}/${params.id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch (err) {
          if (err?.error?.error) {
            toast?.error("Network error");
          }
          toast.error(err?.error?.data?.data?.message, {
            autoClose: 5000,
          });
        }
      },
      providesTags: ["User"],
    }),
  }),
});

export const {
  useJoinTeamRequestMutation,
  useContactUsMutation,
  useChangeCurrencyMutation,
  useGetPayeeDetailsQuery,
  useGetGoogleReviewsQuery,
  useDisconnetGoogleQuery,
  useSendGoogleIdMutation,
} = UserApi;
