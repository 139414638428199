export const loginUrl = "/auth/login"
export const RegisterUrl = "/auth/register"
export const getOtp = "/auth/get-otp"
export const verifyOtp = "/auth/verifyOTP"
export const resendOtp = "/auth/resendOTP"
export const imageUrl = "/user/upload"
export const forgotPassword = "/auth/forgot-password"
export const verifyResetPassword = "/auth/verify-password-otp"
export const resetPassword = "/auth/reset-password"
export const googleLogin = "/auth/google-login"
export const socialLogin = "/auth/initial-social-login"
export const socialAuthRegister  = "/auth/social-login"
export const updateUser = "/user/update"
export const updateBusiness = "/business"
export const deleteAccount = "/user/delete"
export const getTeamById = "/teams"
export const changePassword = "/auth/changesPassword"
export const CheckConnectAccountStatusUrl = "/user/checkStripAccount"