import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faStarHalfStroke,
  faStarHalfAlt,
} from "@fortawesome/free-solid-svg-icons";

const RatingStars = ({ value, text, color, card }) => {
  console.log("value", value);
  return (
    <div className="rating">
      <span>
        {value >= 1 ? (
          <FontAwesomeIcon icon={faStar} color="#FFB33E" />
        ) : value >= 0.5 ? (
          <FontAwesomeIcon icon={faStarHalfStroke} color="#FFB33E" />
        ) : (
          <i className="far fa-star" style={{ color: "#FFB33E" }}></i>
        )}
      </span>
      <span>
        {value >= 2 ? (
          <FontAwesomeIcon icon={faStar} color="#FFB33E" />
        ) : value >= 1.5 ? (
          <FontAwesomeIcon icon={faStarHalfStroke} color="#FFB33E" />
        ) : (
          <i className="far fa-star" style={{ color: "#FFB33E" }}></i>
        )}
      </span>
      <span>
        {value >= 3 ? (
          <FontAwesomeIcon icon={faStar} color="#FFB33E" />
        ) : value >= 2.5 ? (
          <FontAwesomeIcon icon={faStarHalfStroke} color="#FFB33E" />
        ) : (
          <i className="far fa-star" style={{ color: "#FFB33E" }}></i>
        )}
      </span>
      <span>
        {value >= 4 ? (
          <FontAwesomeIcon icon={faStar} color="#FFB33E" />
        ) : value >= 3.5 ? (
          <FontAwesomeIcon icon={faStarHalfStroke} color="#FFB33E" />
        ) : (
          <i className="far fa-star" style={{ color: "#FFB33E" }}></i>
        )}
      </span>
      <span>
        {value >= 5 ? (
          <FontAwesomeIcon icon={faStar} color="#FFB33E" />
        ) : value >= 4.5 ? (
          <FontAwesomeIcon icon={faStarHalfStroke} color="#FFB33E" />
        ) : (
          <i className="far fa-star" style={{ color: "#FFB33E" }}></i>
        )}
      </span>
      {card !== "review" && (
        <span className="ms-1 textGreen fw-bold rating-text">{`(${
          text ?? 0
        })`}</span>
      )}
    </div>
  );
};

RatingStars.defaultProps = {
  color: "#FFB33E",
};

export default RatingStars;
