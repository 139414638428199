import { createSlice } from '@reduxjs/toolkit';
// Import the reducer from the separate file
const UserSlice = createSlice({
  name: 'Users',
  initialState: { threads: [], messages: [] },
  reducers: {
  allNotifications: (state, action) => {
    state.notifications = action.payload;
  },
  allReviews: (state, action) => {
    state.googleReviews = action.payload;
  },
  },
});

export const { allNotifications,allReviews } = UserSlice.actions;
export default UserSlice.reducer;
