import React, { useEffect, useState } from "react";
import "./SideBar.scss";
import {
  BellIcon,
  CheatIcon,
  Dashboard,
  HomeIcon,
  UserIdIcon,
  CheatIconActive,
  BellIconActive,
  DashboardActive,
  UserIdIconActive,
} from "./SvgIcons/icons.jsx";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ConfirmationModal from "../../ConfirmationModal/ConfirmationModal";
import SideBarUserInfo from "./SideBarUserInfo";
import { Button } from "react-bootstrap";

import { logout } from "../../../Store/Auth/slices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { googleLogout } from "@react-oauth/google";
const Sidebar = ({ events }) => {
  // const state = useSelector((state) => state?.Auth?.user);
  // const location = useLocation();
  const [show, setShow] = useState(false);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  let { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const [isShow, setIsHide] = useState("userinfon");
  const hideAndShow = () => {
    setIsHide(!isShow);
  };

  const Items = [
    {
      name: t("Home"),
      route: "/",
      icon1: HomeIcon,
      iconActive: DashboardActive,
    },
    {
      name: t("Account"),
      route: "/Home",
      icon1: Dashboard,
      iconActive: DashboardActive,
    },
    {
      name: t("Chat"),
      route: "/chat",
      icon1: CheatIcon,
      iconActive: CheatIconActive,
    },
    {
      name: t("Notifications"),
      route: "/notifications",
      icon1: BellIcon,
      iconActive: BellIconActive,
    },
    {
      name: t("Profile"),
      route: "/myAccounts",
      icon1: UserIdIcon,
      iconActive: UserIdIconActive,
    },
   
  ];

  const [activeTab, setActiveTab] = useState(null);
  let location = useLocation();

  useEffect(() => {
    if (location.pathname === "/welcome") {
      setActiveTab("/Home");
    } else {
      setActiveTab(location.pathname);
    }
  }, [location.pathname]);

  const [browserWidth, setBrowserWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setBrowserWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div
        className={`sidebar border ${isOpen ? "active" : ""} ${
          browserWidth < 768 ? "d-none" : ""
        }`}
      >
        <div className="sd-header">
          <Button
            className="btn btn-primary border border-success"
            id="closeBtn"
            onClick={() => {
              toggleSidebar();
              hideAndShow();
            }}
          >
            <FontAwesomeIcon icon={faBars} />
          </Button>
        </div>
        <SideBarUserInfo className={`${isShow ? "userinfo" : "d-none"}`} />
        <div className="sd-body">
          <ul>
            {Items?.map((event, i) => {
              if (event.name === t("Logout")) {
                return (
                  <p key={i}>
                    <ConfirmationModal
                      onClick={() => {
                        // localStorage.setItem("tip", "giveTipAsGuest");
                        if (localStorage.getItem("login") === "google") {
                          googleLogout();
                        }
                        dispatch(logout());
                      }}
                      title1={t(
                        "Are you sure you want to log out of your account"
                      )}
                      show={show}
                      handleClose={() => {
                        setShow(false);
                      }}
                    />

                    <li
                      onClick={() => {
                        setShow((state) => true);
                      }}
                    >
                      <div className="d-flex align-items-center justify-content-center">
                        <i className="p-2 ">{event.icon1}</i>
                        <p className="sd-link m-0">{event.name}</p>
                      </div>
                    </li>
                  </p>
                );
              } else {
                return (
                  <li key={i}>
                    <Link
                      to={event.route}
                      onClick={() => setActiveTab(event.route)}
                    >
                      <div
                        className={`${
                          activeTab === event.route
                            ? "activeNav d-flex align-items-center justify-content-center"
                            : "fontColorNav d-flex align-items-center justify-content-center"
                        }`}
                      >
                        {activeTab === event.route ? (
                          <i className="p-2">{event.iconActive}</i>
                        ) : (
                          <i className="p-2">{event.icon1}</i>
                        )}
                        <p className="sd-link m-0">{event.name}</p>
                      </div>
                    </Link>
                  </li>
                );
              }
            })}
          </ul>
        </div>
        <div></div>
      </div>
      <div
        className={`sidebar-overlay ${isOpen ? "active" : ""}`}
        onClick={toggleSidebar}
      />
    </>
  );
};

export default Sidebar;
