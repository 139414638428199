import { rootReducer } from './reducers';
import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { setupListeners } from '@reduxjs/toolkit/query';
import { AuthApi } from './Auth/api';
import { TransactionsApi } from './Transactions/api';
import { TeamMembersApi } from './TeamMembers/api';
import { ThreadsApi } from './ChatThreads/api';
import { UserApi } from './User/api';
const persistConfig = {
  key: 'root',
  storage,
  version: -1,
  whitelist: ['Auth'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(AuthApi.middleware)
      .concat(TransactionsApi.middleware).concat(TeamMembersApi.middleware)
      .concat(ThreadsApi.middleware)
      .concat(UserApi.middleware),
  //  .concat(postsApi.middleware),
});
setupListeners(store.dispatch);
export const persistor = persistStore(store);
