import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import Cache from "i18next-localstorage-cache";
import postProcessor from "i18next-sprintf-postprocessor";
import LanguageDetector from "i18next-browser-languagedetector";
import ar from "./ar.json"; //arabic
import fr from "./fr.json"; //french
import es from "./es.json"; //Spanish
import en from "./en.json"; // English
import it from "./it.json"; //italic
import de from "./de.json"; //German
i18next
  .use(initReactI18next)
  .use(Backend)
  .use(Cache)
  .use(LanguageDetector)
  .use(postProcessor)
  .init({
    lng: localStorage.getItem("defaultlanguage"),
    supportedLngs: ["ar", "fr", "es", "it", "de", "en"],
    resources: { ar, fr, es, it, de, en }, // Array of supported languages
    fallbackLng: "en", // Default language if the user's preferred language is not available
    backend: {
      /* Backend configuration options if using i18next-http-backend */
    },

    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      useSuspense: false
      
    }
  });

export default i18next;
