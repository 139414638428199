import { createSlice } from "@reduxjs/toolkit";
// Import the reducer from the separate file
import { initialStates } from "./intialStates";
const TeamMembersSlice = createSlice({
  name: "TeamMembers",
  initialState: initialStates,
  reducers: {
    getAllTeams: (state, action) => {
      state.allTeams = action.payload;
    },
    getTeamTransactions: (state, action) => {
      state.teamTransactions = action.payload;
    },
    getTeamMemberProfile: (state, action) => {
      state.teamProfile = action.payload;
    },
    getTeamList: (state, action) => {
      state.teamList = action.payload;
    },
    getPendingList: (state, action) => {
      state.pendingList = action.payload;
    },
    getPlaceId:(state,action)=>{
      state.placeId = action.payload;
    }
  }
});

export const {
  getAllTeams,
  getTeamTransactions,
  getTeamMemberProfile,
  getTeamList,
  getPendingList,
  getPlaceId
} = TeamMembersSlice.actions;
export default TeamMembersSlice.reducer;
