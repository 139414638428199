import { combineReducers } from 'redux';
import AuthSlice from './Auth/slices';
import TransactionsSlice from './Transactions/slices';
import TeamMembersSlice from "./TeamMembers/slices"
import { TeamMembersApi } from './TeamMembers/api';
import ThreadSlice from './ChatThreads/slices';
import { AuthApi } from './Auth/api';
import { TransactionsApi } from './Transactions/api';
import { ThreadsApi } from './ChatThreads/api';
import {UserApi} from "./User/api"
import UserSlice from "./User/slices"
export const rootReducer = combineReducers({
  Auth: AuthSlice,
  Transactions: TransactionsSlice,
  Threads: ThreadSlice,
  User:UserSlice,
  TeamMembers:TeamMembersSlice,
  [AuthApi?.reducerPath]: AuthApi?.reducer,
  [UserApi.reducerPath]:UserApi.reducer,
  [TransactionsApi.reducerPath]: TransactionsApi.reducer,
  [ThreadsApi.reducerPath]: ThreadsApi.reducer,
  [TeamMembersApi.reducerPath]:TeamMembersApi.reducer,
  // [postsApi.reducerPath]: postsApi.reducer,
});
