import { createSlice } from "@reduxjs/toolkit";
// Import the reducer from the separate file
import { initialStates } from "./intialStates";
const ThreadsSlice = createSlice({
  name: "Threads",
  initialState: { threads: [], messages: [] },
  reducers: {
    threads: (state, action) => {
      state.threads = action.payload;
    },
    messages: (state, action) => {
      state.messages = action.payload;
    }
  }
});

export const { threads, messages } = ThreadsSlice.actions;
export default ThreadsSlice.reducer;
