import React, { Suspense, lazy, useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Helmet } from "react-helmet-async";
import routes from "./Routes/AllRoutes";
import LoadingScreen from "./Shared/HelperMethods/LoadingScreen";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import ProtectedRoutes from "./Routes/ProtectedRoutes";
import PublicRoutes from "./Routes/PublicRoutes";
import MainLayoutHome from "./Layouts/MainLayout";
import Error from "./Shared/Error";
import TipConformation from "./Pages/HomePages/GiveTip/TipConformation";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { onMessageListener } from "./utilis/Firebase";
import { requestPermission } from "./utilis/Firebase";
import { store } from "./Store/store";
import { toast } from "react-toastify";

function App() {
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [stripePromise, setStripePromise] = useState(null);
  // const notify = () =>  toast.success(notification);
  let route = useLocation();
  let user = useSelector((state) => state?.Auth?.user);

  useEffect(() => {
    if (notification?.title) {
      setNotification((state) => {});
    }
  }, [notification]);

  requestPermission(user?.accessToken, user?.user?.id);
  onMessageListener()
    .then((payload) => {
      toast.success(
        `${payload?.notification?.title}
      ${payload?.notification?.body}`,
        { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 5000 }
      );
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
    })
    .catch((err) => console.error(err));

  return (
    <React.Fragment>
      <Routes>
        <Route path="*" element={<Error />} />
        {routes.map((route, i) => {
          return (
            <>
              <Route
                key={i}
                path={route.path}
                element={
                  <>
                    {user?.accessToken ? (
                      <>
                        {route.path == "/" ? (
                          <Suspense fallback={<LoadingScreen />}>
                            <>
                              <PublicRoutes
                                Component={route.component}
                                requiredRoles={route.requiredRoles}
                              />
                            </>
                          </Suspense>
                        ) : (
                          <MainLayoutHome>
                            <Suspense fallback={<LoadingScreen />}>
                              <>
                                <ProtectedRoutes
                                  Component={route.component}
                                  requiredRoles={route.requiredRoles}
                                />
                              </>
                            </Suspense>
                          </MainLayoutHome>
                        )}
                      </>
                    ) : (
                      <>
                        <Suspense fallback={<LoadingScreen />}>
                          <PublicRoutes
                            Component={route.component}
                            requiredRoles={route.requiredRoles}
                          />
                        </Suspense>
                      </>
                    )}
                  </>
                }
              />
            </>
          );
        })}
      </Routes>
    </React.Fragment>
  );
}

export default App;
