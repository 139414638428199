export const getTeamByTeamCode= '/teams/get-team-members';
export const getAllTeams = "/teams"
export const getTeamMemberTransactionsById = "/teams/getTransactions"
// export const teamMemberApprovedStatus= "/teams/get-team-members"
export const sendTeamInvite  = "/teams/send-team-invite"
export const makeTeamAdmin = "/teams/make-team-admin"
export const removeTeamAdmin = "/teams/removeAdmin"
export const getTeamProfile = "/teams/get-team-member-profile"
export const changeRequestStatus = "/user/teamjoinApproval"
export const  getTeamMemberRequestLists  = "/teams/requests"
