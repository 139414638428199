import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import UserImage from '../../Assets/Images/AccountImage.png';
import StarImg from '../../Assets/Images/starIcon.svg';
import Button from 'react-bootstrap/Button';
import './confirmPayeeModal.scss';
import { useTranslation } from 'react-i18next';
const ConfirmPayeeModal = ({
  className,
  show,
  handleClose,
  payee,
  payeeCode,
  children,
  confirm,
  cancel,
  title,
  handleConfirm,
}) => {
  let { t } = useTranslation();
  return (
    <Modal
      className={`confirmPyeeModal ${className} modal-dialog-centered `}
      show={show}
      onHide={handleClose}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {title && (
        <Modal.Header className="border-0">
          <Modal.Title className="text-center w-100 text-white my-3">
            {title}
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body className="text-center justify-content-center p-4">
        <Row className="m-0 justify-content-center align-items-center">{children}</Row>
      </Modal.Body>
      <Modal.Footer className="FooterModal p-0">
        <div className="FooterModalCon d-flex align-items-center w-100">
          <div className="w-100">
            <Button
              className=" btn text-white btn-right m-0 w-100"
              variant="secondary"
              onClick={handleConfirm}
            >
              {t(confirm)}
            </Button>
          </div>
          <div className="w-100">
            <Button
              className="btn btn-left m-0 w-100"
              variant="secondary"
              onClick={handleClose}
            >
              {t(cancel)}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmPayeeModal;
