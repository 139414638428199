import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Spinner } from "reactstrap"
import styles from "./style.module.scss";
const LoadingScreen = () => {
  return (
    <div className={styles.loader}>
      <div className={styles.loaderinner}>
      <Spinner/>
      </div>
    </div>
  );
};

export default LoadingScreen;
