import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { giveTipUrl, getTransactionsUrl, getBalanceUrl } from "./endpoint";
import { baseUrl } from "../../utilis/baseUrl";
import { useSelector } from "react-redux";
import { store } from "../store";
import { transactions } from "./slices";

export const TransactionsApi = createApi({
  reducerPath: "TransactionsApi",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  tagTypes: ["Transaction"],

  endpoints: (builder) => ({
    createTransaction: builder.mutation({
      query: (transaction) => {
        return {
          url: giveTipUrl,
          method: "POST",
          body: transaction,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + ""
          }
        };
      },
      invalidatesTags: ["Transaction"]
    }),
    getTransactions: builder.query({
      query: (params) => {
        const token = store.getState().Auth.user?.accessToken;
        return {
          url: `${getTransactionsUrl}?type=${params.transactionType}&search=${params.search}&startDate=${params.startDate}&endDate=${params.endDate}&teamId=${params.teamId}&userTeamId=${params.userTeamId}&userType=${params.userType}&toTeamId=${params.toTeamId}&fromTeamId=${params.fromTeamId}&teamLogin=${params.teamLogin}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`
          }
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        // dispatch(messageCreated('Fetching posts...'))
        try {
          const { data } = await queryFulfilled;
          // dispatch(transactions());
          // `onSuccess` side-effect
          // dispatch(messageCreated('Posts received!'))
        } catch (err) {
          // `onError` side-effect
          // dispatch(messageCreated('Error fetching posts!'))
        }
      },
      providesTags: ["Transaction"]
    }),
    getBalance: builder.query({
      query: (params) => {
        const token = store.getState().Auth.user?.accessToken;
        return {
          url: `${getBalanceUrl}?toTeamId=${params.toTeamId}&userTeamId=${params.userTeamId}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`
          }
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch (err) {}
      },
      providesTags: ["Transaction"]
    })
  })
});

export const {
  useCreateTransactionMutation,
  useGetTransactionsQuery,
  useGetBalanceQuery
} = TransactionsApi;
