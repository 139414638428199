
export let allRoles = ["businessUser",'admin',"tipGiver",'teamOwner',"tipReciever","individualRole","teamAdmin","teamMember","tipGiver"]
export let teamOwnerRole =['teamOwner',"teamAdmin"]
export let tipRecieverRole = ["tipReciever","teamAdmin","businessUser","individualRole","teamMember"]
export let userRole = ['user']
export let businessUserRole = ["businessUser"]
export let individualRole = ["individualRole","teamMember","teamAdmin"]
export let teamAdminRole = ["teamAdmin"]
export let teamMemberRole = ["businessUser",'admin','teamOwner',"tipReciever","individualRole","teamAdmin","tipGiver"]
export let teamMember = ["teamMember"]
