import React from "react";
import { useState } from "react";
import ChangeLanguageModal from "../../Pages/HomePages/MyAccount/ChangeLanguage";
const worldIcon = require("../../Assets/Images/iCons/world.png");
const downIcon = require("../../Assets/Images/iCons/down.png");

const ChangelangIcon = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    <ChangeLanguageModal state={"mainScreen"}/>
    </>
  );
};

export default ChangelangIcon;
