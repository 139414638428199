import React, { useState } from "react";
import "./mainLayout.scss";
import MainHeader from "../Components/MainHeader/index";
import MyNavbar from "../Shared/Nabar/index";

const MainLayoutHome = ({ children }) => {
  return (
    <>
      <div className="d-md-none d-block mb-3">
        <MyNavbar />
      </div>
      <div className="d-flex w-100">
        <MainHeader />
        {children}
      </div>
    </>
  );
};

export default MainLayoutHome;
