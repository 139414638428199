import React from "react";
import "./mainHeading.scss";

const MainHeading = ({ heading, color, className }) => {
  return (
    <div className="MainHeading my-2 mb-4">
      <h1 className={`${className} heading1 mb-0  ${color}`}>{heading}</h1>
    </div>
  );
};

export default MainHeading;
