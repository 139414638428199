import { createSlice } from "@reduxjs/toolkit";
// Import the reducer from the separate file
import { initialStates } from "./intialStates";
const TransactionsSlice = createSlice({
  name: "Transactions",
  initialState: { transactions: [] },
  reducers: {
    transaction: (state, action) => {
      state.transactions = action.payload;
    }
  }
});

export const { transaction} = TransactionsSlice.actions;
export default TransactionsSlice.reducer;
